<template>
  <div class="flex justify-center m-0">
    <div :class="containerSize" class="relative flex justify-center items-center">
      <slot name="logo">
        <img
          v-if="hasLogo"
          class="inline-block spinner-border m-0"
          src="~assets/img/logos/MetaWin-icon-letters-only-white.svg"
          alt="MetaWin"
          :width="logoSize"
          :height="logoSize"
        >
      </slot>
      <img
        class="absolute m-0 spinner-border animate-spin-slowing"
        src="~assets/img/spinners/spinner-thin-blue.png"
        alt="Circle Spinner"
        :width="spinnerSize"
        :height="spinnerSize"
      >
    </div>
  </div>
</template>

<script setup>
const { size, hasLogo, } = defineProps({
  size: {
    type: String,
    default: 'lg',
    validator(value) {
      return ['2xs', 'xs', 'sm', 'md', 'lg',].includes(value);
    },
  },
  hasLogo: {
    type: Boolean,
    default: true,
  },
});

const containerSize = computed(() => {
  const containerSizes = {
    '2xs': 'w-4 h-4',
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
  };

  return containerSizes[size];
});

const spinnerSize = computed(() => {
  const spinnerSizes = {
    '2xs': 20,
    xs: 25,
    sm: 30,
    md: 50,
  };

  return spinnerSizes[size];
});

const logoSize = computed(() => {
  const logoSizes = {
    '2xs': 6,
    xs: 8,
    sm: 9,
    md: 15,
  };

  return logoSizes[size];
});
</script>
